import Dropzone from 'react-dropzone';
import cx from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { File } from './components/File/File';
import stylesOfFile from './components/File/File.module.css';
import styles from './FileManager.module.css';
import { FMFile, FMFileStatus } from './interfaces';

interface Props {
    setFiles: React.Dispatch<React.SetStateAction<FMFile[]>>;
    files: FMFile[];
    uuid?: string;
    model?: number;
    type: number;
    maxAttachmentsLength?: number;
}

export const FileManager = ({
    setFiles,
    files,
    uuid,
    model,
    type,
    maxAttachmentsLength,
}: Props) => {
    const { t } = useTranslation();

    const onUpload = useCallback(
        (completedFile: FMFile) => {
            setFiles((prevState) => {
                return prevState.map((file) => {
                    if (file.index === completedFile.index) {
                        return completedFile;
                    } else {
                        return file;
                    }
                });
            });
        },
        [setFiles]
    );

    const onDelete = useCallback(
        async (index: number) => {
            setFiles((prevState) =>
                prevState.filter((file) => file.index !== index)
            );
        },
        [setFiles]
    );

    const onDrop = useCallback(
        (newFiles: File[]) => {
            const updatedFiles = [
                ...files,
                ...newFiles.map((file, index) => ({
                    id: null,
                    index: files.length + 1 + index,
                    status: FMFileStatus.Uploading,
                    url: '',
                    meta: file,
                })),
            ];
            setFiles(updatedFiles);
        },
        [files, setFiles]
    );

    const isVisibleButton = maxAttachmentsLength
        ? files.length < maxAttachmentsLength
        : true;

    return (
        <div className={styles.container}>
            {files.map((file) => {
                return (
                    <File
                        key={file.index}
                        file={file}
                        uuid={uuid}
                        onUpload={onUpload}
                        onDelete={onDelete}
                        type={type}
                        model={model}
                    />
                );
            })}
            {isVisibleButton && (
                <Dropzone onDrop={onDrop} maxFiles={maxAttachmentsLength}>
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps()}
                            className={cx(
                                stylesOfFile.file,
                                stylesOfFile.button
                            )}
                        >
                            <input {...getInputProps()} />
                            <div className={styles.title}>
                                <PlusOutlined className={stylesOfFile.icon} />
                                {t('add')}
                            </div>
                        </div>
                    )}
                </Dropzone>
            )}
        </div>
    );
};
