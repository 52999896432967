export enum Locales {
    DE = 'de',
    EN = 'en',
    PL = 'pl',
    RU = 'ru',
}

export interface SessionToken {
    accessToken: string;
    payload: {
        type: number;
        model?: number;
        sessionId: string;
        locale: Locales;
        maxAttachmentsLength?: number;
    };
}
