import { useCallback, useEffect, useState } from 'react';
import { Image } from 'antd-mobile';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import styles from './File.module.css';
import { FMFile, FMFileStatus } from '../../interfaces';
import { Delete } from '../Delete/Delete';
import { uploadFile } from '../../../../services/file';

interface FileProps {
    file: FMFile;
    uuid?: string;
    onUpload: (file: FMFile) => void;
    onDelete: (id: number) => void;
    type: number;
    model?: number;
}

export const File = ({
    file,
    uuid,
    onUpload,
    onDelete,
    type,
    model,
}: FileProps) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const onLoad = () => setIsLoading(false);

    const handleUpload = useCallback(async () => {
        setIsLoading(true);
        const uploadedFile = await uploadFile({ file, uuid, type, model });
        onUpload(uploadedFile);
    }, [file, onUpload, uuid, type, model]);

    useEffect(() => {
        if (file.status === FMFileStatus.Uploading) {
            handleUpload();
        }
    }, [file.status, handleUpload]);

    return (
        <div
            className={cx(styles.file, {
                [styles.error]: file.status === FMFileStatus.Failed,
            })}
        >
            <div className={styles.imgContainer}>
                {file.status !== FMFileStatus.Uploading && (
                    <Image
                        onLoad={onLoad}
                        onError={onLoad}
                        className={cx(styles.img, {
                            [styles.invisible]: isLoading,
                        })}
                        src={file.url}
                        fit="contain"
                    />
                )}
                {(isLoading || file.status === FMFileStatus.Uploading) && (
                    <Loading3QuartersOutlined className={styles.spinner} spin />
                )}
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.info}>
                    <div className={styles.name}>{file.meta.name}</div>
                    <div
                        className={cx(styles.status, {
                            [styles.error]: file.status === FMFileStatus.Failed,
                        })}
                    >
                        {t(`fileStatuses.${file.status}`)}
                    </div>
                </div>
                <Delete
                    index={file.index}
                    id={file.id}
                    onDelete={onDelete}
                    className={cx(styles.deleteButton, {
                        [styles.hidden]: file.status === FMFileStatus.Uploading,
                    })}
                />
            </div>
        </div>
    );
};
